import { Button, Grid } from '@material-ui/core';
import React from 'react'
import { useForm } from 'react-hook-form';
import InputText from '../../../../components/Inputs/RHK/InputText';
import InputDate from '../../../../components/Inputs/RHK/InputDate';
// import InputSelect from '../../../components/Inputs/RHK/InputSelect';
// import InputCheckbox from '../../../components/Inputs/RHK/InputCheckbox/InputCheckbox';
// import { dateYesterday } from '../../../../Utils/Date'
import { updateTableFilters } from '../../../../reducers/tableReducer/tableReducer.actions';
import InputSelect from '../../../../components/Inputs/RHK/InputSelect';
import { tfgRequestStatusOptions } from '../../TFG.utils';

const TFGRequestsFilters = ({ dispatch, onDownloadCSV }) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = data => {
    dispatch(updateTableFilters(data));
  }

  const onDownload = () => {
    onDownloadCSV();
  }

  return (
    <div className="requests-filters">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Filtros</h3>
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="degreeId"
            label="ID de titulación"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="termId"
            label="ID de convocatoria"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="areaId"
            label="ID de área temática"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="instructorId"
            label="ID del instructor"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="studentId"
            label="ID del estudiante"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputSelect
            name="status"
            label="Estado de la solicitud"
            options={tfgRequestStatusOptions}
            control={control}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputDate
            name="createdFrom"
            label="Fecha de creación (posterior a)"
            control={control}
            // defaultValue={dateYesterday()}
            defaultValue={null}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputDate
            name="createdTo"
            label="Fecha de creación (anterior a)"
            control={control}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="filters--actions">
            <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
            >
              Aplicar filtros
            </Button>
          </div>
          <div className="filters--actions">
            <Button
                onClick={handleSubmit(onDownload)}
                variant="contained"
                color="primary"
            >
              Descargar CSV
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default TFGRequestsFilters;