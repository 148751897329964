import Http from "../../Utils/Http";
import {localDevCatch} from "../../Utils/utils";
import {mockedUserData} from "./NewEnrollmentsService.mock";

export const getUserData = async (externalUserId = null) => {
    let endpoint = `/enrollments-api/users`
    if(externalUserId){
        endpoint = `/enrollments-api/users/${externalUserId}`
    }
    return await Http.get(endpoint)
        .then(res => {
            if (res.status === 200) return res.data
            if (res.status === 404) return {}
            else {
                throw new Error("Error retrieving user data")
            }
        })
        .catch((err) => localDevCatch(err, mockedUserData));
}