import Http from "../../Utils/Http";
import {localDevCatch} from "../../Utils/utils";
import {mockedGrades} from "./GradesService.mock";
import {mockedEnrollmentTerms} from "./NewEnrollmentsService.mock";
import {mockedCourses} from "./CourseService.mock";

export const getGrades = async () => {
    return await Http.get(`/enrollments-api/grades`)
        .then(res => res.data)
        .catch((err) => localDevCatch(err, mockedGrades));
}

export const getEnrollmentTerms = async () => {
    return await Http.get(`/enrollments-api/terms`)
        .then(res => res.data)
        .catch((err) => localDevCatch(err, mockedEnrollmentTerms));
}

export const getCoursesOfGrades = async (gradeId, termId, available) => {
    return await Http.get(`/enrollments-api/grades/${gradeId}/${termId}/courses`, {params: {available: available}})
        .then(res => res.data)
        .catch((err) => localDevCatch(err, mockedCourses));
}